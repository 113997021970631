<template>
    <div class="multi-selectbox" :class="getModifiers()">
        <multiselect
        v-model="selected"
        :options="options"
        :searchable="canSearchOptions"
        :placeholder="selectPlaceholder"
        :label="label"
        :track-by="trackBy"
        select-label=""
        selected-label=""
        deselect-label=""
        :allow-empty="canSelectEmptyValue"
        :multiple="multiple">
            <template #noResult>
                {{ noResultsText }}
            </template>
            <template #noOptions>
                {{ noOptionsText }}
            </template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    name: "MultiSelectbox",
    components: {
        Multiselect,
    },
    props: {
        selectPlaceholder: {
            type: String,
            default: "SimpleSelectBox",
        },
        canSearchOptions: {
            type: Boolean,
            default: false,
        },
        searchPlaceholder: {
            type: String,
            default: "Type...",
        },
        noResultsText: {
            type: String,
            default: "No results found.",
        },
        noOptionsText: {
            type: String,
            default: "No options to choose from.",
        },
        options: {
            type: Array,
            default: () => [],
        },
        canSelectEmptyValue: {
            type: Boolean,
            default: true,
        },
        setOptionsLater: {
            type: Boolean,
            default: false,
        },
        modelValue: {
            type: [ Object, Number, Array, String, Number ],
        },
        preSelect: {
            type: Boolean,
        },
        label: {
            type: String,
        },
        trackBy: {
            type: String,
        },
        multiple: {
            type: Boolean,
        }
    },
    emits: [ "options-load", "update:modelValue" ],
    data () {
        return {
            choicesInstance: null,
            savedOptions: [],
            selected: null,
        };
    },
    methods: {
        getModifiers () {
            return {
                "simple-selectbox--hidden-empty-option": !this.canSelectEmptyValue,
                "simple-selectbox--search-disabled": !this.canSearchOptions,
            };
        },

        setOptions () {
        },

        selectOptionByValue (value) {
            this.choicesInstance?.setChoiceByValue(value.toString());
            this.$emit("update:modelValue", value.toString());
        },
    },
    watch: {
        selectPlaceholder () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        searchPlaceholder () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        noResultsText () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        noOptionsText () {
            this.setOptions(this.setOptionsLater ? this.savedOptions : this.options);
        },

        options: {
            deep: true,
            handler (value) {
                this.setOptions(value);
            },
        },
        selected (newValue) {
            this.$emit("update:modelValue", newValue);
        },
    },
    mounted () {
    }
}
</script>
<style lang="scss" scoped>
@import "~@/css/globals.scss";
</style>
