<template>
  <div class="flex flex--100 restaurant-list-view">
    <!-- Language and Nav -->
    <language-selector />
    <home-navigation :selected="2" />

    <!-- Page Title -->
    <div class="flex flex--100 choose-a-restaraunt">
      <div class="flex flex--100 flex--x-align-center">
        <h2 class="flex title choose-restaurant-text">
          {{ $t("restaurantList.chooseRestaurant") }}
        </h2>
      </div>
    </div>

    <!-- Restaurant List -->
    <div class="flex flex--100 flex--y-align-center flex--x-align-around restaurant-list">

      <!-- 1) NOVOLI -->
      <div class="restaurant-box">
        <div class="flex flex--100">
            <primary-logo/>
            <h2 class="title">
            giaogiao Novoli &mdash; Firenze
            <span class="new-restaurant-label">NEW</span>
            </h2>
        </div>
        <!-- Carousel for Novoli -->
        <Splide :options="{ type: 'loop', autoplay: false, arrows: true, pagination: false }">
          <SplideSlide
            v-for="(img, index) in imagesNovoli"
            :key="'novoli-' + index"
          >
            <img class="restaurant-image" :src="img" alt="Restaurant photo"/>
          </SplideSlide>
        </Splide>
        <p class="paragraph">
          Via Nicola Tagliaferri, 16/A, 50127, Firenze (FI)
        </p>
         <p class="paragraph"><a class="tel-link" href="tel:+390559868720">Tel. 055 986 8720</a></p>
        <button class="order-button" @click="toOrderView(15)">
          {{ $t("homepage.preorder") }}
        </button>
      </div>

      <!-- 2) DUOMO -->
      <div class="restaurant-box">
         <div class="flex flex--100">
             <primary-logo/>
             <h2 class="title">giaogiao Duomo &mdash; Firenze</h2>
         </div>
        <Splide :options="{ type: 'loop', autoplay: false, arrows: true, pagination: false }">
          <SplideSlide
            v-for="(img, index) in imagesDuomo"
            :key="'duomo-' + index"
          >
            <img class="restaurant-image" :src="img" alt="Restaurant photo"  />
          </SplideSlide>
        </Splide>
        <p class="paragraph">Via dei Servi, 35R, 50122, Firenze (FI)</p>
         <p class="paragraph"><a class="tel-link" href="tel:+390553989000">Tel. 055 398 9000</a></p>
        <button class="order-button" @click="toOrderView(1)">
          {{ $t("homepage.preorder") }}
        </button>
      </div>

      <!-- 3) STAZIONE -->
      <div class="restaurant-box">
         <div class="flex flex--100">
            <primary-logo/>
            <h2 class="title">giaogiao Stazione &mdash; Firenze</h2>
        </div>
        <Splide :options="{ type: 'loop', autoplay: false, arrows: true, pagination: false }">
          <SplideSlide
            v-for="(img, index) in imagesStazione"
            :key="'stazione-' + index"
          >
            <img class="restaurant-image" :src="img" alt="Restaurant photo" />
          </SplideSlide>
        </Splide>
        <p class="paragraph">Via Panzani, 44R, 50123, Firenze (FI)</p>
        <p class="paragraph"><a class="tel-link" href="tel:+390553988770">Tel. 055 398 8770</a></p>
        <!-- <button class="order-button" @click="toOrderView(5)">
          {{ $t("homepage.orderNow") }}
        </button> -->
      </div>

      <!-- 4) BOCCONI -->
      <div class="restaurant-box">
         <div class="flex flex--100">
            <primary-logo/>
            <h2 class="title">giaogiao Bocconi &mdash; Milano</h2>
        </div>
        <Splide :options="{ type: 'loop', autoplay: false, arrows: true, pagination: false }">
          <SplideSlide
            v-for="(img, index) in imagesBocconi"
            :key="'bocconi-' + index"
          >
            <img class="restaurant-image" :src="img" alt="Restaurant photo"/>
          </SplideSlide>
        </Splide>
        <p class="paragraph">Viale Bligny, 45, 20136, Milano (MI)</p>
        <p class="paragraph"><a class="tel-link" href="tel:+390252513355">Tel. 02 5251 3355</a></p>
        <!-- <button class="order-button" @click="toOrderView(3)">
          {{ $t("homepage.orderNow") }}
        </button> -->
      </div>
    </div>

    <!-- Footer -->
    <footer-navigation />
  </div>
</template>


<script>
import FooterNavigation from "@/components/navigation/FooterNavigation";
import LanguageSelector from "@/components/inputs/LanguageSelector";
import HomeNavigation from "@/components/navigation/HomeNavigation";
import { isMobile, } from "@/main";
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/splide.min.css'; 
import PrimaryLogo from "@/components/utilities/PrimaryLogo";

export default {
    name: "RestaurantListView",
    components: {
        HomeNavigation,
        LanguageSelector,
        FooterNavigation,
        Splide,
        SplideSlide,
        PrimaryLogo,
    },
    data() {
        return {
            imagesNovoli: [
                "https://giaogiao.it:3000/images/novoli/1.jpg",
                "https://giaogiao.it:3000/images/novoli/2.jpg",
                "https://giaogiao.it:3000/images/novoli/3.jpg",
                "https://giaogiao.it:3000/images/novoli/4.jpg",
                "https://giaogiao.it:3000/images/novoli/5.jpg",
                "https://giaogiao.it:3000/images/novoli/6.jpg",
                "https://giaogiao.it:3000/images/novoli/7.jpg",
                "https://giaogiao.it:3000/images/novoli/8.jpg"
            ],
            imagesDuomo: [
                "https://giaogiao.it:3000/images/servi/1.jpg",
                "https://giaogiao.it:3000/images/servi/2.jpg",
                "https://giaogiao.it:3000/images/servi/3.jpg",
                "https://giaogiao.it:3000/images/servi/4.jpg",
                "https://giaogiao.it:3000/images/servi/5.jpg",
                "https://giaogiao.it:3000/images/servi/6.jpg",
            ],
            imagesStazione: [
                "https://giaogiao.it:3000/images/panzani/1.jpg",
                "https://giaogiao.it:3000/images/panzani/2.jpg",
                "https://giaogiao.it:3000/images/panzani/3.jpg",
                "https://giaogiao.it:3000/images/panzani/4.jpg",
                "https://giaogiao.it:3000/images/panzani/5.jpg",
                "https://giaogiao.it:3000/images/panzani/6.jpg",
                "https://giaogiao.it:3000/images/panzani/7.jpg",
            ],
            imagesBocconi: [
                "https://giaogiao.it:3000/images/bocconi/1.jpg",
            ],
        };
    },
    methods: {
        toOrderView (restaurantId) {
            if (isMobile || window.innerWidth <= 800) {
                this.$router.push({ path: `/restaurant/${restaurantId}/mobile/order`, });
            }
            else {
                this.$router.push({ path: `/restaurant/${restaurantId}/order`, });
            }
        },
    }
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.paragraph {
    margin-top: 18px;

    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.07rem;
}

.title {
    margin: 0 auto;
    font-size: 32;
    font-weight: bold;
    letter-spacing: 0.07rem;
}

.restaurant-list-view {
    :deep(.language-selector) {
        position: absolute;
        right: 0;
        top: 0;

        z-index: 100000;
        background-color: rgba(255, 255, 255, 0.08);

        .language {
            margin: 0;
            background-color: transparent;

            &__name {
                font-size: 14px;
                font-weight: 400;
            }
            &__icon {
                width: 18px;
            }
            &:hover {
                .language__name {

                }
            }
            &--selected {
                .language__name {
                    color: $primary-brand-color;
                    font-weight: 600;
                }
            }
        }
    }
}

.restaurant-list {
    padding: 30px;
    min-height: 80vh;
    background-color: rgb(0, 0, 0);

    // background-image: url("https://giaogiao.it:3000/images/internal/TRY.jpg");
    background-size: cover;

    .restaurant-box {
        will-change: transform;
        transition: transform 300ms;

        margin: 30px;

        cursor: pointer;
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
        background-color: rgb(255,255,255);
        border-radius: 8px;
        padding: 10px;

        .primary-logo {
            position: relative;

            width: 24px;
            height: 24px;

            fill: rgb(0, 0, 0);
            background-color: $primary-brand-color;
            border-radius: 50%;
            border: 1px solid rgb(250,250,250);
            margin: 8px 5px 0px 0px;
        }

        // &:hover {
        //     transform: scale(1.12);
        // }
        .paragraph {
            font-size: 13px;
        }
        .title {
            font-size: 14px;
            margin: 10px 0 18px 0;
        }

        .splide {
            margin-bottom: 20px;
            height: 400px;
            width: 500px;

            .splide__track {
                border-radius: 10px;
                overflow: hidden;
            }

            .splide__arrow--prev,
            .splide__arrow--next {
                color: #fff; 
                background: rgba(0, 0, 0, 0.5);
            }

            .splide__pagination__page {
                background: #fff;
            }
        }

        .restaurant-image {
            width: 100%;
            height: 100%;
            max-height: 375px;
            object-fit: cover;
        }
    }

    @media (max-width: 448px) {
        .restaurant-box {
            margin: 0;
            margin-bottom: 10px;
            .splide {
                width: 100%;
                height: auto;
            }
            .restaurant-image {
                max-height: 262px;
             }
        }
    }
}

 @media (max-width: 448px) {
    .restaurant-list {
        padding: 10px;
    }
}

.choose-restaurant-text {
    margin: 40px auto;
    color: rgb(0, 0, 0);
    font-size: 32px;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    font-style: oblique;
}

.new-restaurant-label {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 5px 3px;
    background-color: red;
    color: white;
    font-size: 1.2rem;
    font-weight: 500;
    border-radius: 4px;

    @media (max-width: 448px) {
        top: 15px;
        right: 15px;
    }
}

.order-button {
  margin-top: 15px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  background-color: $primary-brand-color;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  text-transform: uppercase;

  &:hover {
    background-color: darken($primary-brand-color, 10%);
  }
}

.tel-link {
  text-decoration: none;
  color: rgb(7,7,7);
}
</style>
