<template>
    <div class="flex flex--100 flex--x-align-center simple-section simple-section--black footer">
        <div class="flex flex--80 flex--x-align-center">
            <primary-logo/>
        </div>
        <div class="flex flex--90 flex--x-align-around">
            <div class="flex flex--direction-column information-box contact-box">
                <h2 class="title">{{$t("homepage.contact")}}</h2>
                <div>
                    <p class="paragraph">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
                            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
                        </svg>
                        0553989000
                    </p>
                </div>
                <div class="flex--100">
                    <p class="paragraph">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon">
                            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline>
                        </svg>
                        shizhanhuashang@gmail.com
                    </p>
                    <p class="paragraph">
                        P.IVA 02487680973
                    </p>
                </div>
                <div class="flex">
                    <a class="social-button" href="https://www.facebook.com/GiaoGiao-100584998740713">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon">
                            <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-4 7.28V4.5h-2.29c-2.1 0-3.42 1.6-3.42 3.89v1.67H8v2.77h2.29v6.67h2.85v-6.67h2.29l.57-2.77h-2.86V8.94c0-1.1.58-1.66 1.72-1.66H16z"/>
                        </svg>
                    </a>
                    <a class="social-button" href="https://instagram.com/giao.giao.it?igshid=pt97ognyzc69">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="icon">
                            <path d="M20 0a4 4 0 0 1 4 4v16a4 4 0 0 1-4 4H4a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4h16zm-4.89 4.5H8.9C6.33 4.5 4.6 6.15 4.5 8.66V15.09c0 1.3.42 2.41 1.27 3.23a4.34 4.34 0 0 0 2.88 1.17l.27.01h6.16c1.3 0 2.4-.42 3.18-1.18a4.25 4.25 0 0 0 1.23-2.95l.01-.26V8.9c0-1.28-.42-2.36-1.21-3.15a4.24 4.24 0 0 0-2.92-1.23l-.26-.01zm-6.2 1.4h6.24c.9 0 1.66.26 2.2.8.47.5.77 1.18.81 1.97V15.1c0 .94-.32 1.7-.87 2.21-.5.47-1.17.74-1.98.78H8.92c-.91 0-1.67-.26-2.21-.78-.5-.5-.77-1.17-.81-2V8.88c0-.9.26-1.66.8-2.2a2.98 2.98 0 0 1 2-.78h6.45-6.23zM12 8.1a3.88 3.88 0 0 0 0 7.74 3.88 3.88 0 0 0 0-7.74zm0 1.39a2.5 2.5 0 0 1 2.48 2.48A2.5 2.5 0 0 1 12 14.45a2.5 2.5 0 0 1-2.48-2.48A2.5 2.5 0 0 1 12 9.49zm4.02-2.36a.88.88 0 1 0 0 1.76.88.88 0 0 0 0-1.76z"/>
                        </svg>
                    </a>
                </div>
            </div>
            <div class="flex flex--direction-column information-box address-box">
                <h2 class="title">{{$t("homepage.addressesTitle")}}</h2>
                <div class="address">
                    <a class="address-link" target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=giaogiao&destination_place_id=ChIJ0dsUOvhVKhMRNmziQL3eW_I">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" class="icon">
                            <path d="M10,1.375c-3.17,0-5.75,2.548-5.75,5.682c0,6.685,5.259,11.276,5.483,11.469c0.152,0.132,0.382,0.132,0.534,0c0.224-0.193,5.481-4.784,5.483-11.469C15.75,3.923,13.171,1.375,10,1.375 M10,17.653c-1.064-1.024-4.929-5.127-4.929-10.596c0-2.68,2.212-4.861,4.929-4.861s4.929,2.181,4.929,4.861C14.927,12.518,11.063,16.627,10,17.653 M10,3.839c-1.815,0-3.286,1.47-3.286,3.286s1.47,3.286,3.286,3.286s3.286-1.47,3.286-3.286S11.815,3.839,10,3.839 M10,9.589c-1.359,0-2.464-1.105-2.464-2.464S8.641,4.661,10,4.661s2.464,1.105,2.464,2.464S11.359,9.589,10,9.589"></path>
                        </svg>
                        Via dei Servi, 35R, 50122, Firenze (FI)
                    </a>
                </div>
                <div class="address">
                    <a class="address-link" target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=giaogiao&destination_place_id=ChIJkwFnIrRXKhMRyfg_VmiSgYY">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" class="icon">
                            <path d="M10,1.375c-3.17,0-5.75,2.548-5.75,5.682c0,6.685,5.259,11.276,5.483,11.469c0.152,0.132,0.382,0.132,0.534,0c0.224-0.193,5.481-4.784,5.483-11.469C15.75,3.923,13.171,1.375,10,1.375 M10,17.653c-1.064-1.024-4.929-5.127-4.929-10.596c0-2.68,2.212-4.861,4.929-4.861s4.929,2.181,4.929,4.861C14.927,12.518,11.063,16.627,10,17.653 M10,3.839c-1.815,0-3.286,1.47-3.286,3.286s1.47,3.286,3.286,3.286s3.286-1.47,3.286-3.286S11.815,3.839,10,3.839 M10,9.589c-1.359,0-2.464-1.105-2.464-2.464S8.641,4.661,10,4.661s2.464,1.105,2.464,2.464S11.359,9.589,10,9.589"></path>
                        </svg>
                        Via Nicola Tagliaferri, 16/A, 50127 Firenze FI
                    </a>
                </div>
                <div class="address">
                    <a class="address-link" target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=giaogiao&destination_place_id=ChIJX1vcXiNXKhMRsw5Dgd06FW8">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" class="icon">
                            <path d="M10,1.375c-3.17,0-5.75,2.548-5.75,5.682c0,6.685,5.259,11.276,5.483,11.469c0.152,0.132,0.382,0.132,0.534,0c0.224-0.193,5.481-4.784,5.483-11.469C15.75,3.923,13.171,1.375,10,1.375 M10,17.653c-1.064-1.024-4.929-5.127-4.929-10.596c0-2.68,2.212-4.861,4.929-4.861s4.929,2.181,4.929,4.861C14.927,12.518,11.063,16.627,10,17.653 M10,3.839c-1.815,0-3.286,1.47-3.286,3.286s1.47,3.286,3.286,3.286s3.286-1.47,3.286-3.286S11.815,3.839,10,3.839 M10,9.589c-1.359,0-2.464-1.105-2.464-2.464S8.641,4.661,10,4.661s2.464,1.105,2.464,2.464S11.359,9.589,10,9.589"></path>
                        </svg>
                        Via Panzani, 44R, 50123, Firenze (FI)
                    </a>
                </div>
                <div class="address">
                    <a class="address-link" target="_blank" href="https://www.google.com/maps/dir/?api=1&destination=giaogiao&destination_place_id=ChIJJ5DOyBLFhkcRT6xRXw5Pbk8">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20" class="icon">
                            <path d="M10,1.375c-3.17,0-5.75,2.548-5.75,5.682c0,6.685,5.259,11.276,5.483,11.469c0.152,0.132,0.382,0.132,0.534,0c0.224-0.193,5.481-4.784,5.483-11.469C15.75,3.923,13.171,1.375,10,1.375 M10,17.653c-1.064-1.024-4.929-5.127-4.929-10.596c0-2.68,2.212-4.861,4.929-4.861s4.929,2.181,4.929,4.861C14.927,12.518,11.063,16.627,10,17.653 M10,3.839c-1.815,0-3.286,1.47-3.286,3.286s1.47,3.286,3.286,3.286s3.286-1.47,3.286-3.286S11.815,3.839,10,3.839 M10,9.589c-1.359,0-2.464-1.105-2.464-2.464S8.641,4.661,10,4.661s2.464,1.105,2.464,2.464S11.359,9.589,10,9.589"></path>
                        </svg>
                        Viale Bligny, 45, 20136, Milano (MI)
                    </a>
                </div>
            </div>
            <div class="flex flex--100 flex--x-align-center policy-box">
                <cookie-policy class="policy-box__link"></cookie-policy> 
                <privacy-policy class="policy-box__link"></privacy-policy>
            </div>
        </div>
    </div>
</template>

<script>
import PrimaryLogo from "@/components/utilities/PrimaryLogo";
import CookiePolicy from '@/components/utilities/legal/CookiePolicy';
import PrivacyPolicy from '@/components/utilities/legal/PrivacyPolicy';

export default {
    name: "FooterNavigation",
    components: {
        PrimaryLogo,
        CookiePolicy,
        PrivacyPolicy,
    }
}
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.paragraph {
    margin-top: 28px;

    font-size: 28px;
    font-weight: 400;
    letter-spacing: 0.07rem;
}

.title {
    font-size: 48px;
    font-weight: bold;
    letter-spacing: 0.07rem;
}

.information-box {
    margin: 100px 10px;

    .title {
        font-size: 20px;
        text-transform: uppercase;

        color: rgb(255, 255, 255);
    }
    .paragraph {
        font-size: 18px;
        color: rgb(255, 255, 255);
    }
}

.simple-section {
    position: relative;
    margin: 100px 0;

    &--black {
        margin: 0;
        background-color: rgb(0, 0, 0);
    }
}

.footer {
    .primary-logo {
        margin: 100px 0 0 0;
        fill: rgb(255, 255, 255);
    }

    .title {
        margin-bottom: 20px;
    }
}

.address-box {
    .icon {
        transform: translate(0px, 3px);
        width: 20px;
        fill: rgb(255, 255, 255);
    }

    .address {
        margin-top: 28px;
        cursor: pointer;

        &:hover {
            .address-link {
                color: $primary-brand-color;
            }

            .icon {
                fill: $primary-brand-color;
            }
        }

        .address-link {
            text-decoration: none;
            font-size: 18px;
            font-weight: 400;
            letter-spacing: 0.07rem;
            color: rgb(255, 255, 255);
        }
    }
}

.contact-box {
    .icon {
        width: 20px;
        fill: rgb(254, 254, 254);
        stroke: rgb(0, 0, 0);
        transform: translate(0px, 5px);
    }
    .social-button {
        cursor: pointer;
        margin-right: 16px;
        .icon {
            margin-top:28px;
            width: 32px;
            fill: rgb(254, 254, 254);
        }

        &:hover {
            .icon {
                fill: $primary-brand-color;
            }
        }
    }
}

.policy-box {
    margin: 20px 20px;
    &__link {
        margin-left: 20px;
        text-decoration: none;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.07rem;
        color: rgb(255, 255, 255);
    }
}
</style>
