<template>
    <div class="flex restaurant-product" @click="onClick" :class="getModifiers()">
        <div class="flex flex--100 flex--x-align-end editor-controls" v-if="showEditor">
            <simple-button v-if="!isArchived" :text="$t('management.restaurantProductManagementTab.discount')" @click="discount"/>
            <simple-button v-if="!isArchived" :text="$t('management.restaurantProductManagementTab.modify')" @click="updateProduct" />
            <simple-button v-if="!isArchived" :text="$t('management.restaurantProductManagementTab.archive')" @click="archive"/>
<!--            <simple-button v-if="!isArchived" text="REMOVE" @click="remove"/>-->
            <simple-button v-if="isArchived" :text="$t('management.restaurantProductManagementTab.unarchive')" @click="unarchive"/>
        </div>
        <div class="flex flex--100 flex--y-align-center restaurant-product__box" @click="onProductBoxClick">
            <span v-if="!lite && increaseFreeVolume" class="restaurant-product__free">{{ $t("generic.free") }}</span>
            <div class="flex flex--x-align-center flex--y-align-center allergen-list-box" :class="getAllergenListBoxModifiers()" ref="allergenList" @click="showAllergenListModalBox">
                <span class="flex show-allergen-list-button">
                    <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" viewBox="0 0 111.577 111.577" class="show-allergen-list-button__icon">
                        <path d="m78.962 99.536-1.559 6.373c-4.677 1.846-8.413 3.251-11.195 4.217-2.785.969-6.021 1.451-9.708 1.451-5.662 0-10.066-1.387-13.207-4.142-3.141-2.766-4.712-6.271-4.712-10.523 0-1.646.114-3.339.351-5.064.239-1.727.619-3.672 1.139-5.846l5.845-20.688c.52-1.981.962-3.858 1.316-5.633.359-1.764.532-3.387.532-4.848 0-2.642-.547-4.49-1.636-5.529-1.089-1.036-3.167-1.562-6.252-1.562-1.511 0-3.064.242-4.647.71-1.59.47-2.949.924-4.09 1.346l1.563-6.378c3.829-1.559 7.489-2.894 10.99-4.002 3.501-1.111 6.809-1.667 9.938-1.667 5.623 0 9.962 1.359 13.009 4.077 3.047 2.72 4.57 6.246 4.57 10.591 0 .899-.1 2.483-.315 4.747-.21 2.269-.601 4.348-1.171 6.239l-5.82 20.605c-.477 1.655-.906 3.547-1.279 5.676-.385 2.115-.569 3.731-.569 4.815 0 2.736.61 4.604 1.833 5.597 1.232.993 3.354 1.487 6.368 1.487 1.415 0 3.025-.251 4.814-.744 1.784-.493 3.085-.926 3.892-1.305zm1.476-86.506c0 3.59-1.353 6.656-4.072 9.177-2.712 2.53-5.98 3.796-9.803 3.796-3.835 0-7.111-1.266-9.854-3.796-2.738-2.522-4.11-5.587-4.11-9.177 0-3.583 1.372-6.654 4.11-9.207C59.447 1.274 62.729 0 66.563 0c3.822 0 7.091 1.277 9.803 3.823 2.721 2.553 4.072 5.625 4.072 9.207z"/>
                    </svg>
                </span>
            </div>
            <div class="flex restaurant-product-not-available" v-if="!isAvailable">
                <span>{{ $t("generic.unavailable") }}</span>
            </div>
            <div class="flex flex--100 preview-vertical" v-if="layout === RestaurantProductLayout.VERTICAL && !lite">
                <div class="preview-vertical__image" :style="getVerticalPreviewImageStyle(product)">
                    <span v-if="onePlusOne" class="flex flex--x-align-center flex--y-align-center restaurant-product__onePlusOne">
                        {{ $t("discountStrategy.onePlusOne" )}}
                    </span>
                    <span v-else-if="fixedDiscount" class="flex flex--x-align-center flex--y-align-center restaurant-product__discount">
                        -{{ fixedDiscount }}€
                    </span>
                    <span v-else-if="percentageDiscount" class="flex flex--x-align-center flex--y-align-center restaurant-product__discount">
                        -{{ percentageDiscount }}%
                    </span>
                    <span v-else-if="newArrival" class="flex flex--x-align-center flex--y-align-center restaurant-product__new-arrival">
                        {{ $t("generic.newArrival") }}
                    </span>
                </div>
            </div>
            <div class="flex flex--100 restaurant-product__card" :class="getRestaurantProductCardModifiers()">
                <div class="flex flex--70" :class="getRestaurantProductCardNameModifiers()">
                    <div class="flex flex--100">
                        <h2 class="restaurant-product__name">{{ localizedName }}</h2> 
                        <template v-if="layout === RestaurantProductLayout.MOBILE || lite">
                            <template v-if="onePlusOne">
                                <br>
                                <span class="flex restaurant-product__onePlusOne_mobile">
                                    {{ $t("discountStrategy.onePlusOne" )}}
                                </span>
                            </template>
                            <template v-else-if="fixedDiscount">
                                <br>
                                <span class="flex restaurant-product__discount_mobile">
                                    -{{ fixedDiscount }}€
                                </span>
                            </template>
                            <template v-else-if="percentageDiscount">
                                <br>
                                <span class="flex restaurant-product__discount_mobile">
                                    -{{ percentageDiscount }}%
                                </span>
                            </template>
                            <template v-else-if="newArrival">
                                <br>
                                <span class="flex restaurant-product__new-arrival_mobile">
                                    {{ $t("generic.newArrival") }}
                                </span>
                            </template>
                        </template>
                    </div>
                    
                    <div class="flex flex--100" v-if="restaurantProcessor.restaurant.id === 6 || showDescription">
                        <p class="restaurant-product__description">
                            {{ localizedDescription }}
                        </p>
                    </div>

                    <div class="flex flex--100 restaurant-product__price-box">
                        <span v-if="!lite && increaseFreeVolume" class="restaurant-product__price">€ 0.00</span>
                        <span v-else class="restaurant-product__price" :class="fixedDiscount || percentageDiscount ? 'red' : ''">€ {{ getProductFinalPrice }}</span>
                        <span class="restaurant-product__offer" v-if="fixedDiscount || percentageDiscount">€ {{ normalizePriceToDisplay(product.price) }}</span>
                        
                        <span v-if="!product.showEditor && product.popular && product.categories.findIndex((c) => c.excludeFromTopSeller) === -1 && !isPos" class="flex flex--y-align-center restaurant-product__popular">
                            <svg viewBox="0 0 154 145" xmlns="http://www.w3.org/2000/svg">
                                <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
                                    <polygon points="77 120 29.9771798 144.72136 38.9577393 92.3606798 0.915478696 55.2786405 53.4885899 47.6393202 77 0 100.51141 47.6393202 153.084521 55.2786405 115.042261 92.3606798 124.02282 144.72136"/>
                                </g>
                            </svg>
                            {{ $t("generic.popular" )}}
                        </span>
                    </div>
                </div>
                <div class="flex flex--30 preview-horizontal" v-if="layout === RestaurantProductLayout.HORIZONTAL && !lite">
                    <img class="preview-horizontal__image" :src="product.previewImage.uri" alt="" v-if="product.previewImage && true">
                </div>
                <div class="flex flex--30 preview-mobile" v-if="layout === RestaurantProductLayout.MOBILE && !lite">
                    <img class="preview-mobile__image" :src="product.previewImage.uri" loading="lazy" alt="" v-if="product.previewImage">
                </div>
            </div>
        </div>
        <div class="flex flex--y-align-center selection-controls" v-show="isSelected && maxSelectableVolume > 1">
            <span class="flex flex--x-align-center flex--y-align-center selection-controls__minus" @click="decreaseVolume(1)">
                <svg viewBox="0 0 100 12" xmlns="http://www.w3.org/2000/svg" class="flex">
                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                        <rect x="0" y="0" width="100" height="11" rx="5.5"/>
                    </g>
                </svg>
            </span>
            <span class="selection-controls__volume">{{ selectedVolume }}</span>
            <span class="flex flex--x-align-center flex--y-align-center selection-controls__plus" @click="increaseVolume(1)">
                <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" class="flex">
                    <g stroke="none" stroke-width="1" fill-rule="evenodd">
                        <rect x="0" y="44" width="100" height="11" rx="5.5"/>
                        <rect x="44" y="0" width="11" height="100" rx="5.5"/>
                    </g>
                </svg>
            </span>
        </div>

        <modal-box ref="descriptorModalBox">

        </modal-box>
        <modal-box ref="allergenListModalBox" :append-to-body="true" :show-close-button="false" :show-back-button="true" :close-on-background-click="true">
            <span v-if="product.allergens && product.allergens.length === 0" class="flex flex--100 flex--x-align-center product-no-allergens">{{ $t("productList.productHasNoAllergens") }}</span>
            <div class="flex flex--100 flex--x-align-center" v-else>
                <span class="allergen" v-for="allergen in product.allergens" :key="allergen.id">
                    {{ restaurantProcessor.getAllergenLocalizedName(allergen.id) }}
                </span>
            </div>
        </modal-box>
        <div v-if="showEditor">
            <update-product-modal-box
                v-if="!isFixedMenu"
                ref="UpdateProductModalBox"
                :categories="categories"
                :allergens="allergens"
                :variations="variations"
                :product-tags="productTags"
                @product-update="onProductUpdate"
            ></update-product-modal-box>
            <update-fixed-menu-modal-box 
                ref="UpdateFixedMenuModalBox"
                v-else
                :restaurantPresentation="restaurantPresentation"
                :categories="categories"
                @product-update="onProductUpdate"
            />
            <update-product-discount-strategies-modal-box
                ref="UpdateProductDiscountStrategiesModalBox"
                :categories="categories"
            ></update-product-discount-strategies-modal-box>
        </div>
    </div>
</template>

<script>
import ModalBox from "@/components/containers/ModalBox";
import SimpleButton from "@/components/inputs/SimpleButton";
import UpdateProductModalBox from "@/components/windows/UpdateProductModalBox";
import UpdateFixedMenuModalBox from "@/components/windows/UpdateFixedMenuModalBox";
import { cloneDeep, tap, set, } from "lodash";
import { user, } from "@/user";
import { notificationCenter, } from "@/components/utilities/NotificationCenter";
import { RestaurantProcessor, BasketProcessor } from '@/utilities';
import UpdateProductDiscountStrategiesModalBox from "@/components/windows/UpdateProductDiscountStrategiesModalBox";
import { DiscountStrategies } from "@/products/DiscountStrategies";

export const RestaurantProductLayout = {
    HORIZONTAL: "horizontal",
    VERTICAL: "vertical",
    MOBILE: "mobile",
};

export default {
    name: "RestaurantProduct",
    components: {
        UpdateProductDiscountStrategiesModalBox,
        ModalBox,
        SimpleButton,
        UpdateProductModalBox,
        UpdateFixedMenuModalBox,
    },
    props: {
        layout: {
            type: String,
            default: RestaurantProductLayout.HORIZONTAL,
        },
        product: {
            type: Object,
        },
        restaurantPresentation: {
            type: Object,
        },
        categories: {
            type: Array,
            default: () => [],
        },
        allergens: {
            type: Array,
            default: () => [],
        },
        variations: {
            type: Array,
            default: () => [],
        },
        productTags: {
            type: Array,
            default: () => [],
        },
        isSelectable: {
            type: Boolean,
            default: true,
        },
        maxSelectableVolume: {
            type: Number,
            default: 99,
        },
        value: {
            type: Object,
            default: () => ({}),
        },
        showEditor: {
            type: Boolean,
            default: false,
        },
        lite: {
            type: Boolean,
            default: false,
        },
        isArchived: {
            type: Boolean,
            default: false,
        },
        showAnalytics: {
            type: Boolean,
            default: false,
        },
        isFixedMenu: {
            type: Boolean,
            default: false,
        },
        showDescription: {
            type: Boolean,
            default: false,
        }
    },
    emits: [
        "archive",
        "unarchive",
        "update-volume",
        "click",
        "update",
        "remove",
    ],
    data () {
        return {
            RestaurantProductLayout,
        };
    },
    methods: {
        getModifiers () {
            return {
                "restaurant-product--horizontal": this.layout === RestaurantProductLayout.HORIZONTAL,
                "restaurant-product--vertical": this.layout === RestaurantProductLayout.VERTICAL,
                "restaurant-product--mobile": this.layout === RestaurantProductLayout.MOBILE,
                "restaurant-product--not-available": !this.isAvailable,
                "restaurant-product--selectable": this.isSelectable,
                "restaurant-product--selected": this.isSelected,
            };
        },

        getRestaurantProductCardModifiers () {
            return { "restaurant-product-mobile__card": this.layout === RestaurantProductLayout.MOBILE, };
        },

        getRestaurantProductCardNameModifiers () {
            return {
                "restaurant-product-mobile__name-box": this.layout === RestaurantProductLayout.MOBILE, 
                "flex--100": !(this.layout === RestaurantProductLayout.HORIZONTAL && !this.lite) && !(this.layout === RestaurantProductLayout.MOBILE && !this.lite),
            };
        },

        getAllergenListBoxModifiers () {
            return { "allergen-list-box-mobile": this.layout === RestaurantProductLayout.MOBILE, };
        },

        getLocalization (languageIso) {
            return this.product.localizations.find((localization) => localization.languageIso === languageIso);
        },

        getVerticalPreviewImageStyle (product) {
            if (!product.previewImage) {
                return;
            }

            return {
                "background-image": `url("${product.previewImage.uri}")`,
            };
        },

        update (key, value) {
            this.$emit("update-volume", tap(cloneDeep(this.value), input => set(input, key, value)), this.product.id);
        },

        onClick (event) {
            this.$emit("click", event);
        },

        async onProductUpdate (product) {
            this.$emit("update", product);
        },

        async remove () {
            try {
                const id = await user.deleteProduct(this.product.id);

                if (id) {
                    this.$emit("remove", this.product.id);
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.productRemoveSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.productRemoveFail`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({text: this.$t(`notification.networkError`), });
                console.log(error);
            }
        },

        async archive () {
            try {
                const id = await user.archiveProduct(this.product.id);

                if (id) {
                    this.$emit("archive", this.product.id);
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.productArchivingSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
        },

        async unarchive () {
            try {
                const id = await user.unarchiveProduct(this.product.id);

                if (id) {
                    this.$emit("unarchive", this.product.id);
                    notificationCenter?.sendSuccessNotification({ text: this.$t(`notification.productUnarchivingSuccess`), });
                }
                else {
                    notificationCenter?.sendFailureNotification({ text: this.$t(`notification.serverError`), });
                }
            }
            catch (error) {
                notificationCenter?.sendFailureNotification({ text: this.$t(`notification.networkError`), });
                console.log(error);
            }
        },

        onProductBoxClick (event) {
            const allergenList = this.$refs.allergenList;

            if (event.target === allergenList || allergenList.contains(event.target)) {
                return;
            }

            if (this.isAvailable && this.isSelectable) {
                if (this.maxSelectableVolume === 1 && this.selectedVolume === 1) {
                    this.decreaseVolume(1);
                }
                else {
                    this.increaseVolume(1);
                }
            }
        },

        increaseVolume (quantity) {
            this.update("selectedVolume", Math.min(this.selectedVolume + quantity, this.maxSelectableVolume));

            //this.$emit("increase-volume", this.product);
        },

        decreaseVolume (quantity) {
            this.update("selectedVolume", Math.max(this.selectedVolume - quantity, 0));
        },

        updateProduct () {
            if (this.isFixedMenu) {
                this.$refs.UpdateFixedMenuModalBox.load(this.product);
                this.showUpdateFixedMenuWindow();
            }
            else {
                this.$refs.UpdateProductModalBox.load(this.product);
                this.showUpdateProductWindow();
            }
        },

        showUpdateProductWindow () {
            this.$refs.UpdateProductModalBox.window.show();
        },

        showUpdateFixedMenuWindow () {
            this.$refs.UpdateFixedMenuModalBox.window.show();
        },

        showAllergenListModalBox () {
            this.$refs.allergenListModalBox.show();
        },

        discount () {
            this.$refs.UpdateProductDiscountStrategiesModalBox.load(this.product);
            this.$refs.UpdateProductDiscountStrategiesModalBox.window.show();
        },
    },
    computed: {
        restaurantProcessor () {
            return new RestaurantProcessor({ restaurantPresentation: this.restaurantPresentation, });
        },

        // <basket>
        basketProcessor () {
            return new BasketProcessor({
                basket: this.basket,
                restaurantProcessor: this.restaurantProcessor,
            });
        },

        localizedName () {
            return this.getLocalization(this.$i18n.locale)?.name ?? "";
        },

        localizedDescription () {
            return this.getLocalization(this.$i18n.locale)?.description ?? "";
        },

        selectedVolume () {
            return this.value?.selectedVolume ?? 0;
        },

        isAvailable () {
            return this.product.availableVolume === -1 || this.product.availableVolume > 0;
        },

        isSelected () {
            return this.selectedVolume > 0;
        },

        isPos () {
            return this.lite && this.layout === RestaurantProductLayout.HORIZONTAL;
        },

        validDiscount () {
            return !this.product.discountExpirationTimestamp || this.product.discountExpirationTimestamp > Date.now();
        },

        onePlusOne () {
            return this.product.discountStrategies?.includes(DiscountStrategies.onePlusOne.id) && this.validDiscount;
        },

        fixedDiscount () {
            if (!this.product.discountStrategies || !this.validDiscount) {
                return false;
            }
            const fixedDiscount = this.product.discountStrategies.find((s) => String(s).startsWith("-"));
            return fixedDiscount ? fixedDiscount.substring(1) : false;
        },

        percentageDiscount () {
            if (!this.product.discountStrategies || !this.validDiscount) {
                return false;
            }
            const percentageDiscount = this.product.discountStrategies.find((s) => String(s).startsWith("%"));
            return percentageDiscount ? percentageDiscount.substring(1) : false;
        },

        increaseFreeVolume () {
            return this.onePlusOne && this.selectedVolume % 2 > 0;
        },

        getProductFinalPrice () {
            if (this.percentageDiscount) {
                return this.normalizePriceToDisplay(this.product.price - (this.product.price * Number.parseFloat(this.percentageDiscount) / 100));
            }
            if (this.fixedDiscount) {
                return this.normalizePriceToDisplay(this.product.price - Number.parseFloat(this.fixedDiscount));
            }
            return this.normalizePriceToDisplay(this.product.price);
        },

        newArrival () {
            if (!this.product || !this.product.productTags) {
                return false;
            }
            return this.product.productTags?.find((p) => String(p.id) === String(this.restaurantProcessor.getNewProductTag()?.id));
        },
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.restaurant-product {
    transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);

    position: relative;

    &__box {
        transition: box-shadow 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);

        position: relative;
        margin: 0;
        padding: 0;

        background-color: rgb(254, 254, 254);
        border-radius: 6px;
        border: 3px solid rgba(0, 0, 0, 0);
        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    }
    &--selectable &__box {
        cursor: pointer;
    }
    &--not-available &__box {
        cursor: initial;
    }
    &--selected &__box {
        //border-color: rgba(236, 186, 71, 0.8);
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.15), 0 10px 10px rgba(0, 0, 0, 0.12);
    }
    &--selected {
        transform: scale(1.028) translateZ(0);
    }

    &__card {
        margin: 0;
        padding: 25px;
    }
    &--selected.restaurant-product--vertical &__card {
        border-color: rgba(236, 186, 71, 0.8);
    }

    &__name {
        @extend .--unselectable;

        min-height: 36px;

        color: rgb(20, 20, 20);
        font-size: 16px;
        font-weight: 600;
    }

    &__description {
        @extend .--unselectable;

        margin-top: 5px;
        color: rgb(60, 60, 60);
        font-size: 15px;
        font-weight: 300;
    }

    &__price {
        @extend .--unselectable;

        margin-top: 8px;
        color: rgb(60, 60, 60);
        font-size: 16px;
        font-weight: 500;
    }

     &__offer {
        @extend .--unselectable;
        text-decoration: line-through;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
    }

     &__free {
        @extend .--unselectable;

        position: absolute;
        width: 100%;
        z-index: 1000;
        padding: 5px 10px;
        color: rgb(255, 255, 255);
        background-color: rgb(220, 70, 65);
        border-radius: 3px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
        // text-wrap: nowrap;

        /* Centrare assolutamente */
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        /* Per allineamento centrale */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__price-box {
        gap: 10px;
    }
    
    &__popular {
        margin-top: 8px;
        color: rgb(217, 118, 43);
        font-size: 16px;
        font-weight: 500;
    }
    &__popular > svg {
        width: 16px;
        margin: 0 3px 0 0;
        padding: 0;
        fill: rgb(217, 118, 43);
    }
    &__onePlusOne {
        margin: 8px 35px 0 10px;
        padding: 2px 4px;
        color: rgb(255, 255, 255);
        background-color: rgb(241, 191, 65);
        font-size: 18px;
        font-weight: 600;
        border-radius: 6px;
        border: 3px solid rgb(255, 255, 255);
    }
    &__onePlusOne_mobile {
        margin: 2px 0 6px 10px;
        padding: 2px 4px;
        color: rgb(255, 255, 255);
        background-color: rgb(241, 191, 65);
        border: 1px solid rgb(222,222,222);
        font-size: 11px;
        font-weight: 600;
        border-radius: 6px;
    }
    &__new-arrival {
        margin: 8px 35px 0 10px;
        padding: 2px 4px;
        color: rgb(255, 255, 255);
        background-color:  rgb(0, 204, 102);
        font-size: 18px;
        font-weight: 600;
        border-radius: 6px;
        border: 3px solid rgb(255, 255, 255);
    }
    &__new-arrival_mobile {
        margin: 2px 0 6px 10px;
        padding: 2px 4px;
        color: rgb(255, 255, 255);
        background-color: rgb(0, 204, 102);
        border: 1px solid rgb(222,222,222);
        font-size: 11px;
        font-weight: 600;
        border-radius: 6px;
    }
    &__discount {
        margin: 8px 35px 0 10px;
        padding: 2px 4px;
        color: rgb(255, 255, 255);
        background-color: rgb(255, 69, 0);
        font-size: 18px;
        font-weight: 600;
        border-radius: 6px;
        border: 3px solid rgb(255, 255, 255);
    }
    &__discount_mobile {
        margin: 2px 0 6px 10px;
        padding: 2px 4px;
        color: rgb(255, 255, 255);
        background-color: rgb(255, 69, 0);
        border: 1px solid rgb(222,222,222);
        font-size: 11px;
        font-weight: 600;
        border-radius: 6px;
    }

}
.restaurant-product, .restaurant-product * {
    will-change: transform, box-shadow;
}

.preview-horizontal {
    &__image {
        @extend .--unselectable;

        width: 100%;

        border-radius: 6px;
    }
}

.preview-vertical {
    margin: 5px;
    height: 170px;

    &__image {
        @extend .--unselectable;

        width: 100%;
        max-width: inherit;
        height: inherit;
        max-height: inherit;

        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border-radius: 6px;
    }
}

.restaurant-product-not-available {
    @extend .--unselectable;

    cursor: default;
    position: absolute;
    z-index: 9999;

    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(255, 255, 255, 0.8);
    border-radius: inherit;
}
.restaurant-product-not-available > span {
    position: relative;
    top: 40%;

    width: 100%;
    margin: 0 auto;
    padding: 20px 30px;
    background-color: rgba(255, 255, 255, 0.8);

    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: rgb(20, 20, 20);
}

.selection-controls {
    position: absolute;
    left: 50%;
    bottom: 0;

    width: 139px; // 139px = minus button width + volume text width + plus button width
    height: 43px; // 43px = button height + 1px

    background-color: rgb(236, 186, 71);
    border-radius: 1000px;

    transform: translateX(-50%) translateY(50%);
    z-index: 10;

    &__volume {
        @extend .--unselectable;

        width: 55px;
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    &__minus {
        @extend .--unselectable;

        cursor: pointer;
        color: rgb(255, 255, 255);
        width: 42px;
        height: 42px;
        background-color: rgb(236, 186, 71);
        border-radius: 50%;
    }
    &__plus {
        @extend .--unselectable;

        cursor: pointer;
        color: rgb(255, 255, 255);
        width: 42px;
        height: 42px;
        background-color: rgb(236, 186, 71);
        border-radius: 50%;
    }
    &__minus svg,
    &__plus svg {
        width: 18px;
        fill: rgb(255, 255, 255);
    }
}

.editor-controls {
    transition: 256ms opacity;

    margin: 0 0 10px 0;

    opacity: 0;
}
.restaurant-product:hover > .editor-controls {
    opacity: 1;
}

.analytics {
    margin: 10px 0 0 0;
    padding: 0;

    &__total-orders-count {
        font-size: 12px;
        font-weight: 500;
        color: rgb(126, 126, 126);
    }
}

.editor-controls {
    :deep(.simple-button) {
        min-width: initial;
        padding: 5px 12px;

        box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.012), 0 0 6px 0 rgba(0, 0, 0, 0.024);
    }
    :deep(.simple-button .simple-button__text) {
        padding: 0;
        font-size: 11px;
    }
    :deep(.simple-button .simple-button__loading-spinner) {
        transform: translate(-50%, -50%) scale(0.14);
    }
    :deep(.simple-button + .simple-button) {
        margin-left: 6px;
    }
}

.allergen-list-box {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;

    padding: 10px 8px;

    background-color: rgb(254, 254, 254);
    border-radius: 0 0 0 6px;
}

.show-allergen-list-button {
    &__icon {
        width: 16px;
        height: auto;

        fill: rgb(18, 18, 18);
    }
}

.allergen {
    text-align: center;
    font-size: 22px;
    font-weight: 600;
    text-transform: uppercase;

    padding: 10px;
    margin: 40px 20px;

    border-radius: 12px;

}

.analytics-box {
    margin-top: 20px;
}

.product-no-allergens {
    margin-top: 30px;
    font-size: 30px;
}

.popular-icon {
    margin: 0px 10px;
    width: 20px;
    height: 20px;
}

.preview-mobile {
    padding: 10px 10px 10px 5px;

    &__image {
        height: 88px;
        width: 88px;
        object-fit: contain;
    }
}

.restaurant-product-mobile {
    &__card {
        padding: 5px;
    }

    &__name-box {
        padding: 20px 5px 20px 20px;
    }
}
.allergen-list-box-mobile {
    position: absolute;
    top: 8px;
    right: 0;
    z-index: 100;

    padding: 5px 4px;

    background-color: rgb(254, 254, 254);
    border-radius: 0 0 0 3px;
}

.red {
    color: firebrick;
}
</style>
