export const zh = {
  brandName: "餃姣",
  generic: {
    totalItem: "总数",
    totalPage: "总页数",
    add: "添加",
    cash: "现金",
    cashier: "收银台",
    order: "订单",
    card: "刷卡",
    thirdParty: "第三方",
    cancel: "取消",
    clear: "清除",
    continue: "继续",
    confirm: "确认",
    selectPaymentMethod: "选择付款方式",
    products: "产品",
    rest: "找零",
    date: "日期",
    takeAway: "打包",
    exit: "关闭",
    back: "返回",
    close: "关闭",
    table: "桌",
    times: "时间",
    today: "今天",
    print: "打印",
    notApplicable: "N/A",
    paymentThroughCashier: "点击前往收银台付款",
    paymentThroughWeChatPay: "点击使用微信支付",
    invalidDate: "日期不可选取",
    hasMissingChoices: "还差一样 | 还需要选择{choices}样",
    press: "点击",
    toShowAllergenTab: "查看菜品过敏原",
    popular: "最受欢迎",
    update: "更新",
    unavailable: "售空",
    chooseScene: "选择场景",
    allScene: "全场景",
    save: "保存",
    cover: "座位费人数",
    paid: "已付款",
    openFileBrowser: "打开文件目录",
    showCategories: "显示分类",
    showThirdParty: "显示第三方",
    backToTop: "自动回到顶部",
    all: "全部",
    free: "点击领取免费",
    thirdPartyTotal: "第三方总计",
    username: "用户名",
    password: "密码",
    access: "登录",
    gotIt: "好的",
    default: "默认，如果没有其他语言版本就只需要放这一张。",
    waitOther: "请等待其他人完成其余菜品",
    showHideOrders: "显示隐藏订单",
    prebilling: "跳过小票",
    delete: "删除",
    modifyOrder: "修改订单【开发中】",
    reprintFiscalReceipt: "重新打印带税小票（没出时使用）",
    printIntern: "打印内部用小票",
    expirationTime: "截止时间",
    timeRemaining: "剩余时间：",
    days: "天",
    hours: "小时",
    minutes: "分钟",
    seconds: "秒",
    newArrival: "新上架",
    selectTag: "选择标签",
    platform: "平台上付款",
    pickupCash: "店内现金",
    pickupCard: "店内刷卡",
    void: "出作废单据",
    closed: "关门",
    reservation: "线上预约：",
    status: "状态",
    fixStuck: "修复卡单",
    clickToContinue: "点击任意位置继续",
    cny: "新年特惠",
  },
  notification: {
    networkError: "网络异常。",
    serverError: "内部错误。",
    productAddedSuccess: "产品添加成功。",
    productRemoveSuccess: "产品删除成功。",
    productRemoveFail: "产品移除失败，已销售产品不可移除。",
    productArchivingSuccess: "产品归档成功。",
    productUnarchivingSuccess: "产品取消归档成功。",
    productUpdatedSuccess: "产品更新成功。",
    productStockChangedSuccess: "产品库存更新成功。",
    restaurantOpenSuccess: "餐厅开门成功。",
    restaurantCloseSuccess: "餐厅关门成功。",
    websiteOrderEnabledSuccess: "网页点单系统启动成功。",
    websiteOrderDisabledSuccess: "网页点单系统关闭成功。",
    paymentAfterServiceEnabledSuccess: "先服务后结账启动成功。",
    paymentAfterServiceDisabledSuccess: "先服务后结账关闭成功。",
    kioskEnabledSuccess: "点餐机后台启动成功。",
    kioskDisabledSuccess: "点餐机后台关闭成功。",
    maxDeliveryDistanceChangeSuccess: "配送距离修改成功。",
    stripeConfigurationChangeSuccess: "Stripe配置修改成功。",
    coverChangeSuccess: "座位费修改成功。",
    skipDeliveryStateEnabledSuccess: "跳过配送步骤启动成功。",
    skipDeliveryStateDisabledSuccess: "跳过配送步骤关闭成功。",
    skipPreparationStateEnabledSuccess: "跳过备菜步骤启动成功。",
    skipPreparationStateDisabledSuccess: "跳过备菜步骤关闭成功。",
    customStyleChangeSuccess: "自定义样式修改成功。",
    passwordChangeSuccess: "密码修改成功。",
    passwordChangeFail: "密码修改失败。",
    allergenAddedSuccess: "过敏原添加成功。",
    allergenUpdatedSuccess: "过敏原更新成功。",
    allergenDeletedSuccess: "过敏原删除成功。",
    categoryAddedSuccess: "类别添加成功。",
    categoryUpdatedSuccess: "类别更新成功。",
    tableAddedSuccess: "餐桌添加成功。",
    tableUpdatedSuccess: "餐桌修改成功。",
    tableRemovedSuccess: "餐桌删除成功。",
    variationAddedSuccess: "变化添加成功。",
    variationUpdatedSuccess: "变化更新成功。",
    variationArchivedSuccess: "变化归档成功。",
    variationUnarchivedSuccess: "变化取消归档成功。",
    skipErrorOrders: "个订单未能成功读取。详细信息请查看控制台。",
    serviceTimeUpdatedSuccess: "营业时间修改成功。",
  },
  error: {
    wrongPassword: "密码错误。",
    fileSizeLimitExceeded:
      "文件上传超限, 最大支持 { size } kb，请压缩后再尝试。",
  },
  customerBasket: {
    subtotal: "合计",
    discount: "折扣",
    total: "总计",
    emptyBasket: "您的篮子是空的",
  },
  productList: {
    searchBarPlaceholder: "搜索",
    productHasNoAllergens: "菜品没有过敏原。",
  },
  cashierPos: {
    activeOrders: "订单",
    tables: "座位",
    orderId: "订单号",
    bindOrder: "绑定",
    freeTable: "清桌",
    addProduct: "添加",
    toPay: "支付",
    ordersToPay: "待付款的订单",
    ordersInPreparation: "准备中的订单",
    ordersInDelivery: "派送中的订单",
    completedOrders: "已完成的订单",
    confirmAfterPayment: "仅在支付全额订单金额后才按下此按钮。",
    select: "选择",
    replace: "补单",
    maps: "显示座位图",
    confirmWithoutPayment: "不打印小票，仅在客人要求开发票才按下此按钮。",
    memberOnly: "会员中心",
    removeGlobalDiscount: "取消折扣",
  },
  kitchen: {
    noOrdersToPrepare: "没有订单",
    completedOrders: "已完成的订单",
    stock: "库存",
  },
  pod: {
    dailyOrders: "当日订单",
  },
  kiosk: {
    touchToOrder: "触摸订餐",
  },
  checkout: {
    orderReceiveNowWait: "您将很快收到您的订单,如有任何问题,请致电我们",
    weAreWaitingForYou: "我们正在等你!",
    whereToPay: "支付方式",
    takeAway: "打包",
    preOrder: "预定",
    completePaymentToCashier: "前往收银台完成付款",
    message: "备注",
    checkoutDeliveryErrorType: {
      MISSING_STREET: "请输入地址。",
      MISSING_BUILDING_CODE: "请输入门牌号。",
      MISSING_POSTAL_CODE: "请输入邮编。",
      INCORRECT_POSTAL_CODE: "请检查邮编格式",
      MISSING_FULL_NAME: "请输入姓名。",
      MISSING_MOBILE_PHONE: "请输入电话号码。",
      MISSING_PERSONS: "请输入人数。",
      UNSUPPORTED_ZONE: "您所在地区暂不支持配送。",
      MESSAGE_LENGTH_OVERFLOW: "已超出字数限制",
    },
  },
  order: {
    verifyDeliveryAddress: "验证送货到您的地址",
    suggestedSpecialRequests: [
      "少辣",
      "多辣",
      "不要香菜",
      "不要加肉",
      "不要蔬菜",
      "少盐",
      "多盐",
      "少糖",
      "多糖",
      "冷（饮）",
      "常温",
      "热（饮）",
      "加冰块",
      "不加冰块",
      "加珍珠",
      "不加珍珠",
      "少加点醋",
      "多加点醋",
    ],
    uniqueCode: "唯一代码",
    paymentDate: "支付日期",
    deliveryDate: "交餐日期",
    origin: "订单来源",
    originMap: {
      0: "网页",
      1: "点餐机",
      2: "收银员",
      3: "二维码",
      4: "第三方",
    },
    statusMap: {
      0: "等待支付",
      1: "在筹备",
      2: "已派送",
      3: "派送中",
      4: "已结束",
      5: "已取消",
    },
    paymentMethod: "付款方法",
    paymentMethodMap: {
      0: "现金",
      1: "刷卡",
      2: "其他支付方式",
      3: "Stripe微信支付",
      4: "户户送平台",
      5: "呱呱到家平台",
      6: "Glovo平台",
      7: "优食平台",
      8: "电话下单",
      9: "Just Eat平台",
      10: "其他平台",
      11: "未知",
      12: "个人微信",
      13: "户户送店内现金",
      14: "户户送店内刷卡",
      15: "呱呱到家店内现金",
      16: "呱呱到家店内刷卡",
      17: "Glovo店内现金",
      18: "Glovo店内刷卡",
      19: "优食店内现金",
      20: "优食店内刷卡",
      21: "电话下单店内现金",
      22: "电话下单店内刷卡",
      23: "Just Eat店内现金",
      24: "Just Eat店内刷卡",
      25: "其他平台店内现金",
      26: "其他平台店内刷卡",
      27: "Stripe线上刷卡",
    },
    deliveryLocation: "交餐地点",
    deliveryLocationMap: {
      0: "餐厅内",
      1: "打包",
      2: "送货地址",
    },
    order: "订单",
    discounts: "折扣",
    products: "产品",
    restaurant: "餐厅",
    total: "总计",
    subtotal: "合计",
    discount: "折扣",
    noOrdersToDeliver: "没有配送订单",
    lastOrder: "您最后的订单号是",
    stateAlert: "您很快就会收到订单。",
    orderAgain: "新的订单",
    promoCode: "兑换码",
    receiver: {
      address: "地址",
      name: "姓名",
      mobilePhone: "手机号",
      requestedTime: "预约时间",
      requestedPerson: "预约人数",
    },
    table: "桌号",
    tableAbb: "桌号",
    additions: "添加费用",
    deductions: "减免费用",
  },
  delivery: {
    checkAddress: "检查送货是否到达您的地址",
    checkLater: "稍后检查",
    check: "一探究竟",
    addressTooFar: "我们很抱歉，但送货没有到达指定的地址",
    addressReachable: "我们送货到您所在的地区！ 您可以立即订购",
    checkDifferentAddress: "检查另一个地址",
    deliveryToAddress: "派送到家",
    pickupInRestaurant: "到店自取",
    address: "地址",
    streetNumber: "门牌号",
    postalCode: "邮编",
    fullName: "姓名",
    phoneNumber: "电话号码",
    notes: "备注",
    timePlaceholder: "请选择到达时间",
    numberOfPeople: "人数",
    endOfDay: "今日已关门，请选择其他日期",
    pickDate: "请选择日期",
  },
  pickUp: {
    title: "点餐、到店、取餐或堂食",
    openMap: ">> 查看路线 <<",
    address: "地址",
    serviceTime: "营业时间",
    contact: "联系方式",
    services: "提供的服务",
    pickUp: "自提",
    dineIn: "堂食",
    restaurantInfo: "餐厅信息",
    delivery: "如需外送服务，请联系我们，或者使用以下平台",
    weAreHere: "我们在这里",
  },
  payment: {
    payNow: "现在付款",
    paidConfirmation: "我付了",
    notPaid: "你还没有付款",
    failed: "支付失败，请尝试重新扫码付款。",
  },
  management: {
    generic: {
      daily: "当日",
      weekly: "本周",
      monthly: "本月",
      restaurant: "餐厅",
      name: "名字",
      address: "地址",
      country: "国家",
      vat: "税",
      actions: "操作",
      open: "开",
      close: "关",
      configuration: "设置",
      update: "更新",
      missingValue: "必须填写。",
      invalidValue: "填写错误。",
      origin: "来源",
      amount: "数量",
      total: "总计",
      unitPrice: "单价",
      dailyStatistics: "当日数据",
      revenue: "收入",
      orderOrigin: ["网站", "点餐机", "前台", "二维码", "第三方"],
      export: "导出",
      cancel: "取消",
      import: "导入",
      selectAll: "全选",
      eventManagement: "活动管理",
      date: "日期",
      save: "保存",
      description: "简介",
      categories: "分类",
      price: "价格",
      previewImages: "照片",
      allergens: "过敏源",
      variations: "菜品选项",
      selectCategory: "选择分类| 选择分类",
      selectAllergen: "选择过敏源 | 选择过敏源",
      selectVariation: "选择菜品选项 | 选择菜品选项",
      unit: "份",
      index: "优先级",
      active: "激活",
      deactivated: "停用",
      preview: "预览",
      deleteAds: "删除开屏广告",
      mode: "模式",
      productTag: "标签",
      discount: "折扣",
      serviceTime: "营业时间",
      serviceTimeNote:
        "要添加单独的时间段，请点击“添加”。要关闭选定的日期，请删除所有时间段。",
    },
    primaryUserNavigation: {
      user: "用户",
      management: "管理",
      analytics: "分析",
      productsAnalytics: "菜品分析",
      orders: "订单",
      stock: "库存",
      content: "内容",
      products: "菜品",
      media: "媒体",
      categories: "分类",
      allergens: "过敏原",
      variations: "菜品选项",
      tables: "餐桌",
      events: "活动",
      scenes: "场景",
      poster: "开屏广告",
      fixedMenus: "固定价格套餐",
      customerRelationship: "客户关系",
      customers: "会员",
      promoCodes: "优惠码",
    },
    userTab: {
      username: "用户名",
      password: "密码",
      fullName: "全名",
      role: "职位",
      changePassword: "更改密码",
      oldPassword: "旧密码",
      newPassword: "新密码",
      logout: "登出",
      secure: "安全",
    },
    analyticsTab: {
      closedOrders: "已完成订单",
      unclosedOrders: "未完成订单",
      revenue: "收入",
      totRevenue: "总收入",
      revenueByDay: "收入图",
      averageOrders: "平均订单",
      averageDailyOrders: "平均订单数每日",
      averageOrderTotal: "平均订单收入",
      averageDailyProfit: "平均利润每日",
      averageDailyRevenue: "平均收入每日",
      ordersByDay: "订单图",
      ordersByOrigin: "订单（订单来源）",
      ordersByPaymentMethod: "订单（支付方式）",
      profitByPaymentMethod: "收入（支付方式）只对11月20号前订单信息有效",
      profitByPaymentMethodStripe: "Stripe收入",
      stripeRevenue: "Stripe收入",
      inRestaurant: "餐厅内",
      online: "线上",
      cash: "现金支付(含有外卖平台)",
      card: "刷卡支付(含有外卖平台)",
      stripe: "STRIPE",
      thirdParty: "第三方平台",
      originChartOptions: JSON.stringify({
        labels: [
          "网站",
          "点餐机",
          "收银台",
          "戶戶送",
          "呱呱到家",
          "Glovo",
          "优食",
          "电话呼叫",
          "微信",
          "Just Eat",
          "其他",
        ],
      }),
      paymentMethodChartOptions: JSON.stringify({
        labels: [
          "现金",
          "店内pos机刷卡",
          "户户送平台",
          "呱呱到家平台",
          "Glovo平台",
          "优食平台",
          "电话呼叫",
          "Stripe微信",
          "Just Eat",
          "其他平台",
          "未知",
          // "个人微信",
          // "户户送店内现金",
          // "户户送店内刷卡",
          // "呱呱到家店内现金",
          // "呱呱到家店内刷卡",
          // "Glovo店内现金",
          // "Glovo店内刷卡",
          // "优食店内现金",
          // "优食店内刷卡",
          // "Just Eat店内现金",
          // "Just Eat店内刷卡",
          // "Stripe线上刷卡",
          // "电话下单店内现金",
          // "电话下单店内刷卡",
          // "其他平台店内现金",
          // "其他平台店内刷卡",
        ],
      }),
      stripeChartOptions: JSON.stringify({
        labels: ["Stripe微信", "Stripe线上刷卡"],
      }),
      inRestaurantCashPaymentMethodChartCategories: [
        "现金",
        "户户送店内现金",
        "呱呱到家店内现金",
        "Glovo店内现金",
        "优食店内现金",
        "Just Eat店内现金",
        "电话下单店内现金",
        "其他平台店内现金",
      ],
      inRestaurantCardPaymentMethodChartCategories: [
        "店内pos机刷卡",
        "户户送店内刷卡",
        "呱呱到家店内刷卡",
        "Glovo店内刷卡",
        "优食店内刷卡",
        "Just Eat店内刷卡",
        "电话下单店内刷卡",
        "其他平台店内刷卡",
      ],
      onlinePaymentMethodChartCategories: [
        "Stripe线上刷卡",
        "Stripe微信",
        "户户送平台",
        "呱呱到家平台",
        "Glovo平台",
        "优食平台",
        "Just Eat",
        "电话下单远程付款",
        "其他平台",
        "未知/发票",
        "个人微信",
      ],
      thirdPartyChartCategories: [
        "户户送",
        "呱呱到家",
        "Glovo",
        "优食",
        "Just Eat",
        "其他平台",
      ],
      excelRevenueColumns: [
        "呱呱系统",
        "呱呱到付",
        "Glove系统",
        "Glove店内现金",
        "Glove店内刷卡",
        "外卖系统",
        "堂食现金",
        "堂食POS机",
        "网站自提      网上银行",
        "点餐机     微信支付",
        "手机微信下单       人民币",
        "微信下单     折合欧元",
        "实收欧元",
        "今日总营业额",
        "快递费",
        "送菜费",
        "其他开销",
        "奖金",
      ],
      avgOrdersPerHourPerDay: "每小时平均订单量",
      orders: "单",
    },
    restaurantManagementTab: {
      websiteOrder: "线上订单",
      kioskEnabled: "点餐机",
      paymentAfterService: "用餐后付款",
      skipDeliveryState: "跳过配送",
      skipPreparationState: "跳过备菜",
      maxDeliveryDistance: "配送距离",
      pointOfWork: "工作点",
      customCSS: "自定义样式",
      saveDistance: "保存距离",
      cover: "座位费",
      saveCover: "保存座位费",
      createEndpoint: "添加新端",
    },
    restaurantVariationsTab: {
      newVariation: "新变化",
      noVariations: "此餐厅没有变化数据。",
      active: "活跃变化",
      archived: "归档变化",
    },
    restaurantTableManagementTab: {
      newTable: "新餐桌",
      noTables: "此餐厅没有餐桌数据。",
      update: "修改",
      remove: "删除",
      seats: "座位 (人数)",
      name: "编号",
    },
    restaurantAllergenManagementTab: {
      newAllergen: "新过敏原",
      noAllergens: "此餐厅没有过敏原数据。",
    },
    restaurantCategoryManagementTab: {
      newCategory: "新分类",
      noCategories: "此餐厅没有分类。",
      modifyActiveCategory: "分类激活与排序",
    },
    restaurantProductManagementTab: {
      newProduct: "新产品",
      noProducts: "此餐厅没有产品。",
      active: "活跃产品",
      archived: "归档产品",
      visible: "点餐可见",
      indexRule: "数字越大，菜品越靠前, 默认为所选菜品类型的最后",
      index: "数值",
      visibility: "点餐可见",
      excludeFromTopSeller: "不参与最受欢迎排名",
      modify: "修改名称价格",
      archive: "归档",
      unarchive: "复原",
      discount: "折扣",
      layout: "布局",
      layoutTips:
        "使用说明：先从上方的预览栏像点餐一样选择想要修改的菜品，然后可以通过其他菜品右上角的数值来判断输入的数值会移动到哪个位置。注意：一个菜品可能存在于多种分类，每个分类都有独立的数值，如果选择不参与排行那么所有的分类都不会参与排行也不会显示‘最受欢迎’",
    },
    restaurantFixedMenuManagementTab: {
      newFixedMenu: "新套餐",
      noFixedMenus: "此餐厅没有套餐。",
      selectFixedMenu: "选择套餐菜品",
      createChoice: "添加新页",
      removeChoice: "移除",
      selectFixedMenuProducts: "套餐菜品",
      note: "点击添加新页给套餐添加多个组合菜品，点击移除然后再点击页数图标删除页数，再次点击移除退出移除模式，点击菜品名字添加菜品到套餐的页面上，再次点击菜品从页面上删除。",
    },
    restaurantProductsStockManagementTab: {
      availableVolume: "数量",
      unavailable: "售空",
    },
    restaurantOrderListTab: {
      noOrders: "此餐厅没有订单数据。",
    },
    restaurantProductsAnalyticsTab: {
      noOrders: "此餐厅没有订单数据。",
    },
    restaurantSceneManagementTab: {
      title: "场景管理",
      list: "场景列表",
      newScene: "新场景",
      name: "名称",
      configuration: "配置",
      category: "分类",
      product: "菜品",
      fixedMenu: "套餐",
      customProduct: "自选菜品",
      table: "桌位",
      tableMap: "桌位图",
      active: "显示",
      disable: "隐藏",
      noScenes: "此餐厅没有场景。",
      noMaps: "此场景没有桌位图。",
      noProducts: "此餐厅没有菜品。",
      noCustomProducts: "此餐厅没有自选菜品。",
      noFixedMenus: "此餐厅没有套餐。",
      modify: "修改",
    },
    customerPromoCodesTab: {
      new: "创建新优惠码",
      code: "号码",
      description: "简介",
      discount: "折扣%",
      created: "创建于",
      expiry: "截止",
      lastUpdate: "最后更新于",
      status: "状态",
    },
  },
  homepage: {
    home: "首页",
    ourIdea: "关于我们",
    loyalty: "成为会员",
    orderNow: "即刻点单",
    logIn: "登录",
    findOutMore: "开始探索",
    slogan: "体验为您设计的菜品",
    firstSectionTitle: "全新的外观。熟悉的味道。",
    firstSectionParagraph_1:
      "giaogiao via dei Servi 店的前身是佛罗伦萨甚至是整个托斯卡纳大区最古老的中餐厅，创立于1961年，当时的名字叫做《荷香园》《Fior di Loto》 。",
    firstSectionParagraph_2:
      "而六十年后的今天，giaogiao，一个新式中餐，坚守传统中餐美味配方，改进制作和出餐流程，用更智能化的设备和流程让中餐重焕青春。",
    firstSectionParagraph_3:
      "我们的愿景是：把有滋味的新式（智慧）中餐开遍全世界。",
    firstSectionParagraph_4:
      "我们的使命是：重新定义海外的中餐， 用科技赋能中餐， 让中餐成为真正的现代便捷美食。",
    firstSectionParagraph_5: "口号: 让每一个有文化的城市都有 giaogiao!",
    secondSectionTitle: "为什么叫 giaogiao",
    secondSectionParagraph_1: "我们要做海外中餐的佼佼者！",
    secondSectionParagraph_2: "更年轻（Giovani）的中餐文化传播者。",
    secondSectionParagraph_3: "Giao的缩写来自于下面四个英文单词",
    secondSectionParagraph_4: "Good 好吃",
    secondSectionParagraph_5: "Innovative 创新",
    secondSectionParagraph_6: "Authentic 正宗",
    secondSectionParagraph_7: "Original 原味",
    serviceTimesTitle: "营业时间",
    serviceTimes: "佛罗伦萨店 11:00 - 21:30 全天",
    homeDeliveryTitle: "送餐上门",
    homeDelivery: "5公里以内, 点击点单即刻查看",
    deliveryChargesTitle: "送餐费用",
    deliveryCharges: "1€/km",
    contact: "联系我们",
    addressesTitle: "地址",
    history: "历史",
    present: "今日",
    future: "未来",
    vision: "愿景",
    restaurantSectionTitle: "+3 餐厅",
    restaurantSectionParagraph: "还有更多，敬请期待",
    orderSectionTitle: "+20k 订单",
    orderSectionParagraph: "来自我们的自动系统",
    preorder: "预约取餐",
  },
  restaurantList: {
    chooseRestaurant: "请选择用餐地点",
  },
  loyalty: {
    title: "立即加入饺饺俱乐部，您将在下次光临时收到一份欢迎礼物！",
    firstName: "名 (必填)",
    lastName: "姓 (必填)",
    phone: "电话 (必填)",
    email: "邮箱地址 (必填)",
    birthday: "生日 (必填)",
    subscribe: "订阅",
    read: "已阅读",
    privacy: "隐私政策",
    authorize: "，我授权处理我的个人数据。",
    street: "地址",
    city: "城市'",
    province: "省区",
    postalCode: "邮编",
    optional: "可选",
    newsletter: "我同意通过电子邮件和短信接收新闻通讯和促销信息。(可选）",
    thanks:
      "感谢您成为我们的会员！我们非常高兴，并期待为您提供专属优惠、更新和促销活动。期待您的光临！",
    MISSING_STREET: "请输入地址。",
    MISSING_CITY: "请输入城市。",
    MISSING_POSTAL_CODE: "请输入邮编。",
    INCORRECT_POSTAL_CODE: "请检查邮编格式",
    MISSING_FIRST_NAME: "请输入名。",
    MISSING_LAST_NAME: "请输入姓。",
    MISSING_PHONE: "请输入电话号码。",
    MISSING_PROVINCE: "请输入省区。",
    MISSING_EMAIL: "请输入邮箱。",
    MISSING_BIRTHDAY: "请输入生日。",
    consentEmail: "我希望通过电子邮件接收促销优惠和更新。",
    consentSMS: "我希望通过短信接收促销优惠和更新。",
    consentPhoneCall: "我希望通过电话接收促销优惠和更新。",
  },
  categoryTimes: {
    0: "马上要",
    10: "10分钟",
    20: "20分钟",
    30: "30分钟",
  },
  discountStrategy: {
    onePlusOne: "买一送一",
    percentage: "百分比",
    fixed: "固定减额",
  },
  lang: {
    it: "意大利语",
    zh: "中文",
    en: "英语",
  },
  endpoint: {
    cashier: "收银台端",
    kiosk: "点餐机端",
    kitchen: "厨房端",
    waiter: "服务员端",
  },
  host: {
    logout: "登出",
    access: "登入",
    development: "开发者模式",
    fullScreen: "全屏",
    clearCache: "删除缓存",
    exit: "关闭",
    checkUpdate: "检查更新",
    start: "开始使用",
    openLogs: "查看日志",
    getConfiguration: "读取配置",
    selectDirectory: "选择Axon小票机安装目录",
    voidOrder: "小票信息",
  },
  productTag: {
    new: "新品(1)",
    discount: "折扣(2)",
  },
  memberOnly: {
    promoCode: "优惠码",
    checkInfo: "查看会员信息",
    updateInfo: "更新会员信息",
    check: "检测",
    use: "使用（不可撤回)",
    invalid: "促销代码已不再有效。",
    expired: "促销代码已过期。",
    description: "描述",
    discount: "折扣",
    notApplicable: "不适用",
    validUntil: "有效期至",
    percentage: "百分比",
    applyDiscount: "使用优惠码",
    unavailable: "暂无",
    unlimited: "无限制",
    info: "餐厅信息",
    stripeWebhook: "Stripe线上支付收款记录",
  },
  restaurantClosed: {
    title: "餐厅目前已关闭",
    description: "对造成的不便我们深表歉意。请在营业时间再次光临。",
  },
  day: {
    Monday: "星期一",
    Tuesday: "星期二",
    Wednesday: "星期三",
    Thursday: "星期四",
    Friday: "星期五",
    Saturday: "星期六",
    Sunday: "星期日",
  },
  stripeStatus: {
    requires_action: "等待用户批准",
    succeeded: "支付成功",
    failed: "支付失败",
    canceled: "已取消",
  },
  stripePaymentMethod: {
    wechat_pay: "微信支付",
    card: "刷卡",
  },
  webhookCol: {
    amount: "金额",
    orderId: "订单唯一代号",
    id: "Id",
    timestamp: "日期时间",
    status: "状态",
    paymentMethod: "支付方式",
  },
};
