<template>
    <div class="flex flex--100 category-filter" ref="CategoryFilter" :class="getModifiers">
        <template v-if="compactView">
             <span v-if="showAll" class="category-filter__button" :key="categories[0].id" :class="getCategoryButtonModifiers(categories[0].id)" @click="selectCategory(categories[0])">
                {{ $t("generic.all") }}
            </span>
            <span v-if="discount" class="category-filter__button" :key="100000" :class="getCategoryButtonModifiers(100000)" @click="selectCategory(categories.find(c => c.id === 100000))">
                {{ $t("generic.cny") }}
            </span>
            <span class="category-filter__button" v-for="category in displayCategories" :key="category.id" :class="getCategoryButtonModifiers(category.id)" @click="selectCategory(category)">
                {{ getCategoryLocalizedName(category) }}
            </span>
            <div class="flex flex--100 expand-arrow" v-show="showMore">
                <div class="flex flex--100 flex--x-align-center">
                    <span class="flex arrow-button" @click="toggleExpansion">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" xml:space="preserve"
                            class="flex arrow-button__icon">
                            <path
                                d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                        </svg>
                    </span>
                </div>
            </div>
            <div class="flex flex--100 category-filter__expanded" :class="getExpandedModifiers" :ref="el => expandableRef = el">
                <hr class="separator" />
                <span class="category-filter__button expanded-button" v-for="category in hideCategories" :key="category.id" :class="getCategoryButtonModifiers(category.id)" @click="selectCategory(category)">
                    {{ getCategoryLocalizedName(category) }}
                </span>
            </div>
        </template>
        <template v-else-if="vertical">
            <span v-if="showAll" class="category-filter--vertical__button" :key="categories[0].id" :class="getCategoryButtonModifiers(categories[0].id)" @click="selectCategory(categories[0])">
                {{ $t("generic.all") }}
            </span>
            <span class="category-filter--vertical__button" v-for="category in showAll ? [ ...categories, ].slice(1) : categories" :key="category.id" :class="getCategoryButtonModifiers(category.id)" @click="selectCategory(category)">
                {{ getCategoryLocalizedName(category) }}
            </span>
        </template>
        <template v-else>
            <span v-if="showAll" class="category-filter__button" :key="categories[0].id" :class="getCategoryButtonModifiers(categories[0].id)" @click="selectCategory(categories[0])">
            {{ $t("generic.all") }}
            </span>
            <span v-if="discount" class="category-filter__button" :key="100000" :class="getCategoryButtonModifiers(100000)" @click="selectCategory(categories.find(c => c.id === 100000))">
                {{ $t("generic.cny") }}
            </span>
            <span class="category-filter__button" v-for="category in categoriesList" :key="category.id" :class="getCategoryButtonModifiers(category.id)" @click="selectCategory(category)">
            {{ getCategoryLocalizedName(category) }}
            </span>
        </template>
    </div>
</template>

<script>
export default {
    name: "CategoryFilter",
    props: {
        categories: {
            type: Array,
        },
        modelValue: {
            type: Number,
        },
        compactView: {
            type: Boolean,
            default: false,
        },
        closeOnBackgroundClick: {
            type: Boolean,
            default: true,
        },
        vertical: {
            type: Boolean,
            default: false,
        }
    },
    emits: ["update:modelValue",],
    data () {
        return {
            itemsToShow: 0,
            displayCategories: [...this.categories,],
            hideCategories: [],
            isExpanded: false,
        };
    },
    computed: {
        getModifiers () {
            return {
                "category-filter--expanded": this.isExpanded,
                "category-filter--compact-view": this.compactView,
                "category-filter--vertical": this.vertical,
            };
        },
        getExpandedModifiers () {
            return {
                "expanded-padding": this.isExpanded,
            };
        },
        showAll () {
            return Boolean(this.categories.find((c) => c.id === 0));
        },
        discount () {
            return Boolean(this.categories.find((c) => c.id === 100000));
        },
        showMore () {
            return this.itemsToShow < this.categories.length;
        },
        categoriesList () {
            let output = this.categories;
            if (this.showAll) {
                output = [...this.categories,].slice(1);
            }
            if (this.discount) {
                output = [...this.categories,].filter((c) => c.id !== 100000);
            }
            return output;
        }
    },
    methods: {
        selectCategory (category) {
            this.compact();
            this.$emit("update:modelValue", category.id);
        },

        getCategoryButtonModifiers (id) {
            return {
                "category-filter__button--active": this.modelValue === id,
                "category-filter--vertical__button--active": this.modelValue === id && this.vertical,
            };
        },

        getCategoryLocalization (category, languageIso) {
            return category.localizations.find((localization) => localization.languageIso === languageIso);
        },

        getCategoryLocalizedName (category) {
            return this.getCategoryLocalization(category, this.$i18n.locale)?.name ?? "";
        },

        getCategoryWidth(categoryElement) {
            const style = window.getComputedStyle(categoryElement);
            const width = categoryElement.offsetWidth;
            const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
            return width + margin;
        },
    
        calculateItemsToFit () {
            this.displayCategories = [...this.categories,].filter(c => c.id !== 100000);
            this.$nextTick(() => {
                const containerElement = this.$refs.CategoryFilter;
                if (!containerElement) return;

                const containerWidth = containerElement.offsetWidth - 40;
                const categoryElements = containerElement.querySelectorAll('.category-filter__button');
                const gap = parseInt(getComputedStyle(containerElement).gap) || 0;
                let totalWidth = 0;
                let itemsToFit = 0;

                categoryElements.forEach(categoryElement => {
                    const categoryElementWidth = this.getCategoryWidth(categoryElement);
                    totalWidth += categoryElementWidth + gap;
                    if (totalWidth <= containerWidth) {
                        itemsToFit++;
                    }
                });

                this.itemsToShow = Math.max(itemsToFit, 1);
                this.displayCategories = [...this.categories].slice(0, this.itemsToShow);
                this.hideCategories = [...this.categories,].slice(this.itemsToShow);
            });
        },

        calculateHideCategoriesHeight () {
            const containerElement = this.$refs.CategoryFilter;
            if (!containerElement) return;

            const containerWidth = containerElement.offsetWidth - 40;
            const categoryElements = containerElement.querySelectorAll('.expanded-button');
            const gap = parseInt(getComputedStyle(containerElement).gap) || 0;
            let totalWidth = 0;
            let col = 1;
            categoryElements.forEach(categoryElement => {
                const categoryElementWidth = this.getCategoryWidth(categoryElement);
                totalWidth += categoryElementWidth + gap;
                if (totalWidth > containerWidth) {
                    col++;
                    totalWidth = 0;
                }
            });
            return col * 70 + 6;
        },

        expand () {
            this.isExpanded = true;
        },

        compact () {
            this.isExpanded = false;
        },

        toggleExpansion () {
            this.isExpanded = !this.isExpanded;
            this.$nextTick(() => this.calculateHideCategoriesHeight());
        },

        handleClickOutside (event) {
            if (!event.target.closest('.category-filter')) {
                this.compact();
            }
        },
    },
    watch: {
        '$i18n.locale' () {
            this.calculateItemsToFit();
        },

        isExpanded (value) {
            if (value && this.expandableRef) {
                this.expandableRef.style.height = this.calculateHideCategoriesHeight() + "px";
            }
            else if (this.expandableRef) {
                this.expandableRef.style.height = "0px";
            }
        },
    },
    mounted() {
        this.calculateItemsToFit();
        document.addEventListener('click', this.handleClickOutside);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    },
    created () {
        if (!this.compactView) {
            this.isExpanded = true;
        }
    },
};
</script>

<style lang="scss" scoped>
@import "~@/css/globals.scss";

.category-filter {
    position: relative;
    margin: 0;
    padding: 0;
    gap: 10px;

    &__button {
        @extend .--unselectable;

        cursor: pointer;

        margin: 0;
        padding: 10px 20px;
        z-index: 3;

        border: 2px solid $primary-brand-color;
        border-radius: 1000px;

        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(255, 255, 255);
    }
    // &__button + &__button {
    //     margin-left: 20px;
    // }
    &__button--active {
        background-color: $primary-brand-color;
    }
    &__expanded {
        position: absolute;
        top:80px;
        left: 0;
        background-color: rgb(42,40,42);
        overflow: hidden;
        gap: 20px;
        height: 0;
        z-index: 13;
    }

    &--compact-view &__expanded {
        transition: height 300ms ease-out;
    }
}

.expand-arrow {
    position: absolute;
    top: 55px;
    margin: auto;
}

.arrow-button {
    cursor: pointer;

    margin: 0;
    padding: 10px 14px;
    z-index: 13;

    &__icon {
        will-change: transform;
        transition: transform 300ms;

        width: 20px;
        height: auto;

        fill: $primary-brand-color;
    }
}

.category-filter--expanded .arrow-button {
    &__icon {
        transform: rotate(180deg);
    }
}

.separator {
    width: 100%;
    height: 1px;
    margin: 0;
    padding: 0;
}

.expanded-padding {
    padding: 0 20px 20px 20px;
}

.category-filter--vertical {
    position: relative;
    flex-direction: column;
    margin: 0;
    padding: 0;
    gap: 0;

    height: auto;
    overflow: auto;
    width: inherit;

    &__button {
        @extend .--unselectable;

        cursor: pointer;

        margin: 0;
        padding: 30px 20px;
        z-index: 3;

        border-bottom: 2px solid rgb(0, 0, 0);

        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0.02rem;
        color: rgb(255, 255, 255);
        background-color: rgb(42, 40, 42);

        width: 120px;
        text-align: center;
        text-wrap: balance;

        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 
                    0 6px 20px rgba(0, 0, 0, 0.19);

        transition: box-shadow 0.3s ease-in-out;
        position: relative; 
    }

    &__button:first-child {
        // border-top-right-radius: 12px;
        border-top-left-radius: 12px;
    }

    &__button:last-child {
        // border-bottom-right-radius: 12px;
        border-bottom-left-radius: 12px;
    }

    &__button--active {
        color: $primary-brand-color;
    }

    &__button--active::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 3px;
        background-color: $primary-brand-color;
        z-index: 1;
    }
}

@media (max-width: 1920px) {
   .category-filter--vertical {
    &__button {
        padding: 20px 20px;

        font-size: 14px;
        font-weight: 600;
    }

    &__button:first-child {
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
    }

    &__button:last-child {
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
    }

    &__button--active {
        color: $primary-brand-color;
    }

    &__button--active::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
        width: 3px;
        background-color: $primary-brand-color;
        z-index: 1;
    }
}
}

</style>
